import React, { useState } from 'react'
import logo from "../assets/logo.webp"
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AiOutlineDashboard, AiOutlineUserAdd, AiOutlineLogout, AiOutlineMenu, AiOutlineSetting, AiFillCloseCircle, AiOutlineAntDesign, AiOutlineNotification } from "react-icons/ai";
import defaultUser from "../assets/avatar.webp";
import { MdHolidayVillage, MdLeaderboard } from "react-icons/md";
import { FaAward } from 'react-icons/fa';

const Sidebar = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const openMobileMenu = () => {
        if (mobileMenu) {
            setMobileMenu(false)
        }
        else {
            setMobileMenu(true)
        }
    }
    const navigate = useNavigate();
    function logout() {
        localStorage.clear();
        navigate("/");
    }
    return (
        <div className="wrapper">
            <div className="topbarSection">
                <div className="topbar">
                    <div className="mobile-toggle-menu">
                        <Link to="/dashboard">
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>
                    <nav className="navbar navbar-expand">
                        <div className="user-box dropdown desktopDisplay">
                            <button className="d-flex align-items-center nav-link" role="button">
                                <div className="user-info ps-3">
                                    <div className="userimg">
                                        <img src={defaultUser} alt={defaultUser} />
                                    </div>
                                    <div className="username">
                                        <p className="user-name mb-0 dropdown-toggle" data-bs-toggle="dropdown">Sushil Kumar Raj</p>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/my-profile"><AiOutlineSetting />Settings</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={logout} to="javascript:void(0)"><AiOutlineLogout />LogOut</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div className="mobileDisplay">
                            <button onClick={openMobileMenu}>
                                {mobileMenu ?
                                    (
                                        <AiFillCloseCircle className="mobileMenuIcones" />
                                    )
                                    :
                                    (
                                        <AiOutlineMenu className="mobileMenuIcones" />
                                    )
                                }
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="sidebarWrapper desktopDisplay">
                <div className="simplebar-mask">
                    <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                            <div className="simplebar-content mm-active">
                                <ul className="metismenu mm-show" id="menu">
                                    <li>
                                        <NavLink activeClassName="active" to="/dashboard">
                                            <AiOutlineDashboard />
                                            <span>Dashboard</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/employee">
                                            <AiOutlineUserAdd />
                                            <span>All Employees</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/designation">
                                            <AiOutlineAntDesign />
                                            <span>All Designation</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/holiday">
                                            <MdHolidayVillage />
                                            <span>All Holiday</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/notification">
                                            <AiOutlineNotification />
                                            <span>All Notification</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/award">
                                            <FaAward />
                                            <span>All Award</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeClassName="active" to="/team-leader">
                                            <MdLeaderboard />
                                            <span>All Team Leader</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${mobileMenu ? "" : "mobileMenuActive"}`}>
                <div className="mobilesidebarWrapper mobileDisplay">
                    <div className="simplebar-mask">
                        <div className="simplebar-offset">
                            <div className="simplebar-content-wrapper">
                                <div className="simplebar-content mm-active">
                                    <ul className="metismenu mm-show" id="menu">
                                        <li>
                                            <NavLink activeClassName="active" to="/dashboard">
                                                <AiOutlineDashboard />
                                                <span>Dashboard</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/employee">
                                                <AiOutlineUserAdd />
                                                <span>All Employees</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/designation">
                                                <AiOutlineAntDesign />
                                                <span>All Designation</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/holiday">
                                                <MdHolidayVillage />
                                                <span>All Holiday</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/notification">
                                                <AiOutlineNotification />
                                                <span>All Notification</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/award">
                                                <FaAward />
                                                <span>All Award</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/dashboard">
                                                <AiOutlineSetting />
                                                <span>Settings</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink activeClassName="active" to="/dashboard">
                                                <AiOutlineLogout />
                                                <span>LogOut</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
