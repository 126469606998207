import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addSingleDataAPI } from '../utils/api';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { AiOutlineAntDesign } from 'react-icons/ai';
import { BsCalendarDate } from 'react-icons/bs';

const AddHoliday = () => {
    const navigate = useNavigate();
    const hrid = localStorage.getItem("hrdata");
    const [values, setUserValue] = useState();

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleHoliday = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                const data = {hr_id:hrid, holiday_name:values.holiday_name, holiday_date:values.holiday_date};
                addSingleDataAPI('hr-add-holiday', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Holiday has been added successfully!");
                        navigate('/holiday');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Holiday already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::ADD HOLIDAY</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Holiday</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleHoliday}>
                                <div className="col-12">
                                    <label for="holiday_name" className="form-label">Holiday Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineAntDesign />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="holiday_name" name="holiday_name" placeholder="Holiday Name" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label for="holiday_date" className="form-label">Holiday Date</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <BsCalendarDate />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="holiday_date" name="holiday_date" placeholder="Holiday Date" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddHoliday