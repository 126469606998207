import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../utils/api'
import LoadingSpinner from '../components/LoadingSpinner'
import { VscTrash } from 'react-icons/vsc'
import Swal from 'sweetalert2'

const TeamLeader = () => {
    const [team, setTeam] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getTeamLeader()
    }, [])

    const getTeamLeader = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-get-all-team-leader').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setTeam(...[res.data.team])
            }
            else {
                setIsLoading(false);
            }
        })
    }
    const handleTeamStatusActive = (teamid) => {
        activeStatus('hr-active-team-status', teamid).then((res) => {
            if (res.data.status === 1) {
                getTeamLeader();
            }
        })
    }
    const handleTeamStatusInactive = (teamid) => {
        activeStatus('hr-inactive-team-status', teamid).then((res) => {
            if (res.data.status === 1) {
                getTeamLeader();
            }
        })
    }
    const handleTeamRemoveHr = (teamid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('hr-remove-team-leader', teamid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getTeamLeader();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="employeepage">
            <Helmet>
                <title>SATKARTAR::HR::TEAM LEADER</title>
            </Helmet>

            <ContentWrapper>
                <div className="card employeepage">
                    <h4 className="mb-0 text-uppercase">
                        All Team Leader
                        <Link to="/add-team-leader" className="btn btn-primary btnemployee"><AiOutlinePlus /> Add Team Leader</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Emp Code</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <LoadingSpinner />
                                    :
                                    team?.map((team, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{team?.emp_name}</td>
                                            <td><span className="badge bg-warning">{team?.emp_code}</span></td>
                                            <td>{team?.emp_mobile}</td>
                                            <td>{team?.emp_company_email}</td>
                                            <td>
                                                {(() => {
                                                    if (team?.leader_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success btnstatus" onClick={() => handleTeamStatusActive(team?.team_leader_id)}>{team?.leader_status}</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger btnstatus" onClick={() => handleTeamStatusInactive(team?.team_leader_id)}>{team?.leader_status}</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-team-leader/${team?.team_leader_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleTeamRemoveHr(team?.team_leader_id)}><VscTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Emp Code</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default TeamLeader