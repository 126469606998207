import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useParams } from 'react-router-dom'
import { fetchAllDataAPI, getSingleDataFromDataBase } from '../utils/api'
import { FaAward, FaBirthdayCake, FaInfo, FaMoneyCheckAlt, FaRupeeSign, FaUserEdit, FaUserTie } from 'react-icons/fa'
import { AiOutlineFilePdf, AiOutlineMail } from 'react-icons/ai'
import { MdContactPage, MdDateRange } from 'react-icons/md'
import moment from 'moment/moment'
import { GiTeapotLeaves } from "react-icons/gi";
import img1 from "../assets/avatar-6.png"
import img2 from "../assets/avatar-7.png"
import img3 from "../assets/avatar-8.png"
import img4 from "../assets/avatar-9.png"
import img5 from "../assets/avatar-10.png"
import { toast } from 'react-toastify'
import LoadingSpinner from '../components/LoadingSpinner'

const EmployeeDetails = () => {
    const [empdetail, setEmpDetail] = useState([]);
    const [employee, setEmployee] = useState([]);
    const[empdata, setEmpdata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { slug } = useParams();
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";

    useEffect(() => {
        getEmpDetails(slug)
        getAllEmployee();
        getDashboardData()
    }, [slug]);

    const getEmpDetails = (slug) => {
        getSingleDataFromDataBase(`hr-get-employee-details/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setEmpDetail(...[res.data.employee]);
            }
        })
    }
    const getAllEmployee = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-all-employee').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const getDashboardData = () => {
        fetchAllDataAPI('hr-dashboard-about-emp-data').then((res)=>{
            if(res.data.status===1){
                setEmpdata(...[res.data.empdata])
            }
            else{
                toast.warning("Check you connection");
            }
        })
    }
    return (
        <div className="employeepage">
            <Helmet>
                <title>SATKARTAR::HR::EMPLOYEES DETAILS</title>
            </Helmet>
            <ContentWrapper>
                <div className="employeedetail">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/employee">Employee </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Employee Details</li>
                                    <li className="breadcrumb-item active" aria-current="page">{slug}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="main-body">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-md-12">
                                    <h4 className="cardtitle">Employee List <span>({empdata?.temp} Employees)</span> <Link className="viewallemp" to="/employee">View All</Link></h4>
                                    <hr />
                                    <div className="employeelist">
                                        {isLoading ?
                                            <LoadingSpinner />
                                            :
                                            employee?.map((employee, index) => (
                                                <Link to={`/employee-details/${employee?.emp_code}`} className="" key={index}>
                                                    {(() => {
                                                        if (employee?.emp_code === slug) {
                                                            return (
                                                                <div className="empbody active">
                                                                    <div className="sticky_header__availabl sticky_emp_availabl">
                                                                        <span className="sticky_header__available_circle"></span>
                                                                    </div>
                                                                    <div className="empimg">
                                                                        <img src={BASE_URL + employee?.emp_foto} alt={employee?.emp_foto} />
                                                                    </div>
                                                                    <div className="empshortinfo">
                                                                        <h6>{employee?.emp_name}</h6>
                                                                        <p>{employee?.emp_job_role}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className="empbody">
                                                                    <div className="sticky_header__availabl sticky_emp_availabl">
                                                                        <span className="sticky_header__available_circle"></span>
                                                                    </div>
                                                                    <div className="empimg">
                                                                        <img src={BASE_URL + employee?.emp_foto} alt={employee?.emp_foto} />
                                                                    </div>
                                                                    <div className="empshortinfo">
                                                                        <h6>{employee?.emp_name}</h6>
                                                                        <p>{employee?.emp_job_role}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })()}
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img src={BASE_URL + empdetail?.emp_foto} className="img-fluid empfoto" alt={empdetail?.emp_foto} />
                                                </div>
                                                <div className="col-md-8 empnamedetail">
                                                    <h4 className="empnames">{empdetail?.emp_name}</h4>
                                                    {(() => {
                                                        if (empdetail?.emp_leave_status === "Inactive") {
                                                            return (
                                                                <div className="sticky_header__availabl sticky_availabl">
                                                                    <span className="sticky_header__available_circle"></span>
                                                                    <p>Available for work</p>
                                                                </div>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className="sticky_header__navailabl sticky_availabl">
                                                                    <span className="sticky_header__navailable_circle"></span>
                                                                    <p>Not available for work</p>
                                                                </div>
                                                            )
                                                        }
                                                    })()}

                                                    <p className="mb-1 empdesignation">{empdetail?.designation_name}</p>
                                                    <p className="text-muted font-size-sm">{empdetail?.emp_company_email}</p>
                                                </div>
                                            </div>
                                            <div className="btninfo">
                                                <Link to="/" className="btnsendemail"><AiOutlineMail />Send Email</Link>
                                                <div className="empjoingdetail">
                                                    <div className="dobicons">
                                                        <MdDateRange />
                                                    </div>
                                                    <div className="empjoingdetails">
                                                        <span className="dob">Today</span>
                                                        <span> {moment().format('Do MMM YYYY')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {/* Total Award */}
                                <div className="card awardcard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="award">
                                                <div className="totalaward">
                                                    <h4 className="awardnames">Total awards</h4>
                                                    <span className="totalaward">10</span>
                                                </div>
                                                <div className="awardicons">
                                                    <FaAward />
                                                </div>
                                            </div>
                                            <div className="awardsinfo">
                                                <p>10% higher then last year</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Total Award */}
                                {/* Project */}
                                <div className="card projectcard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="project">
                                                <div className="totalproject">
                                                    <h4 className="projectnames">Project assigned</h4>
                                                    <span className="totalproject">10</span>
                                                </div>
                                                <div className="projecticons">
                                                    <MdContactPage />
                                                </div>
                                            </div>
                                            <div className="projectsinfo">
                                                <p>Pending Project: 05</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project */}
                                {/* Leave */}
                                <div className="card leavecard">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="leave">
                                                <div className="totalleave">
                                                    <h4 className="leavenames">Available Leave</h4>
                                                    <span className="totalleave">{empdetail?.emp_total_leave_available}</span>
                                                </div>
                                                <div className="leaveicons">
                                                    <GiTeapotLeaves />
                                                </div>
                                            </div>
                                            <div className="leavesinfo">
                                                <p>Leave Applied: 05</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project */}
                            </div>
                            <div className="col-lg-9 empinfodetails">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title"><FaInfo />Information </h4>
                                        <Link className="btnInfo" to={`/edit-employee/${empdetail?.employee_id}`}><FaUserEdit />Edit Info</Link>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Name</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Emp Code</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_code}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Mobile</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_mobile}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Company Email</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_company_email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Designation</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.designation_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Role</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_job_role}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Basic Salary</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_basic_salary}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Total CTC</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_ctc}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Department</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_department}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Gender</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_gender}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>DOB</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Joining Date</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_joining_date}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Seating Location</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_seating_location}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Employeer</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.company_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Job Type</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_job_type}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Work From</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_work_form}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Reporting Head</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>Sushil Kumar Raj</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Status</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span className="badge btn-success badgecolor">{empdetail?.emp_status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="personalinfo">
                                    <div className="card-body">
                                        <h4 className="card-title"><FaInfo />Personal Information </h4>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Personal Email</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_personal_email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Present Address</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_company_email}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Permanent Address</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.designation_name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Skill Set</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span className="skilldetail">Php</span>
                                                            <span className="skilldetail">Codeigniter</span>
                                                            <span className="skilldetail">Laravel</span>
                                                            <span className="skilldetail">React JS</span>
                                                            <span className="skilldetail">Node JS</span>
                                                            <span className="skilldetail">MongoDB</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Experience</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_job_role}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Married Status</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_married_status}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Aniversary Date</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_gender}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="empinfo">
                                                        <div className="empinfotitle">
                                                            <span>Linkedin</span>
                                                        </div>
                                                        <div className="empinfoname">
                                                            <span>{empdetail?.emp_department}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row officialdocument">
                                                <h6>Official Document</h6>
                                                <hr />
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Cirtification</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Adhaar Card</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Pan Card</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Offer Latter</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Resume</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="documentinfo">
                                                        <div className="documenttitle">
                                                            <span><AiOutlineFilePdf />Experience Latter</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="emptypedetails">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="cardemptype">
                                                <div className="emptypetitle">
                                                    <h6>Monthly Attendance</h6>
                                                    <div className="selectMonth">
                                                        <select name="selectmonth">
                                                            <option value="Jan">Jan</option>
                                                            <option value="Feb">Feb</option>
                                                            <option value="March">March</option>
                                                            <option value="April">April</option>
                                                            <option value="May">May</option>
                                                            <option value="June">June</option>
                                                            <option value="July">July</option>
                                                            <option value="Aug">Aug</option>
                                                            <option value="Sep">Sep</option>
                                                            <option value="Oct">Oct</option>
                                                            <option value="Nov">Nov</option>
                                                            <option value="Dec">Dec</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="leaveEmpDetail">
                                                    <div className="leave">
                                                        <p>Leave</p>
                                                        <span>06</span>
                                                    </div>
                                                    <div className="halfday">
                                                        <p>Half Day</p>
                                                        <span>00</span>
                                                    </div>
                                                    <div className="fullday">
                                                        <p>Full Day</p>
                                                        <span>06</span>
                                                    </div>
                                                    <div className="sortleave">
                                                        <p>Sort Leave</p>
                                                        <span>02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype">
                                                <div className="emptypetitle">
                                                    <h6>Team Member</h6>
                                                </div>
                                                <div className="totalimgnumber">
                                                    <div className="teammemberimg">
                                                        <div className="img">
                                                            <img src={BASE_URL + empdetail?.emp_foto} alt={empdetail?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img1} alt={empdetail?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img2} alt={empdetail?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img3} alt={empdetail?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img4} alt={empdetail?.emp_foto} />
                                                        </div>
                                                        <div className="imgs">
                                                            <img src={img5} alt={empdetail?.emp_foto} />
                                                        </div>
                                                    </div>
                                                    <div className="teamnumber">
                                                        <span>+02</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype cardemptypes">
                                                <div className="emptypetitle">
                                                    <h6>Birthday</h6>
                                                    <FaBirthdayCake className="birthicons" />
                                                </div>
                                                <div className="empbirthday">
                                                    <span>Friday, 3 November 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="cardemptype cardemptypeb">
                                                <div className="emptypetitle">
                                                    <h6>Last Month Salary</h6>
                                                    <FaMoneyCheckAlt className="birthicons" />
                                                </div>
                                                <div className="lastmonthsalry">
                                                    <span><FaRupeeSign />25000.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div >
    )
}

export default EmployeeDetails