import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { editDataFromList, updateDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { AiOutlineAntDesign } from 'react-icons/ai';
import ContentWrapper from '../components/contentwrapper/ContentWrapper'

const initialState = {
    designation_name: '',
}

const EditDesignation = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);

    useEffect(() => {
        getDesignationData(slug)
    }, [slug]);

    const getDesignationData = (slug) => {
        editDataFromList(`hr-get-single-designation-data/${slug}`).then((res)=>{
            if(res.data.status===1){
                setInputValues(...[res.data.designation])
            }
        })
    }
    const updateDesignationData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-designation-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/designation');
                    toast.success("Designation Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <div className="designationPage">
            <div className="designationPage">
                <Helmet>
                    <title>SATKARTAR::HR::EDIT DESIGNATION</title>
                </Helmet>
                <ContentWrapper>
                    <div className="col-xl-6">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div>
                                        <AiOutlineAntDesign className="svgAddIcons" />
                                    </div>
                                    <h5 className="mb-0 text-danger">EDIT Designation</h5>
                                </div>
                                <hr />
                                <form className="row g-3" method="post" onSubmit={updateDesignationData}>
                                    <div className="col-12">
                                        <label htmlFor="designation_name" className="form-label">Role Name</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-transparent">
                                                <AiOutlineAntDesign />
                                            </span>
                                            <input type="text" className="form-control border-start-0" id="designation_name" name="designation_name" placeholder="Designation Name" value={values.designation_name} onChange={handleInputs} required={true} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-danger px-5">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default EditDesignation