import React, { useEffect, useState } from 'react'
import avatar from "../assets/avatar.webp";
import { FaBirthdayCake } from 'react-icons/fa';
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import moment from 'moment';

const UpcomingBirthday = () => {
    const [birthday, setBirthday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";

    useEffect(() => {
        getAllUpcomingBirthday()
    }, []);

    const getAllUpcomingBirthday = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-all-upcoming-birthday').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setBirthday(...[res.data.birthday]);
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100 topsection">
            <div className="d-flex align-items-center upcomingtitle">
                <div>
                    <h5 className="mb-0">Upcoming Birthday</h5>
                </div>
                <div className="birthdayicons">
                    <FaBirthdayCake />
                </div>
            </div>
            <div className="p-2 ps ps--active-y bodysection">
                {isLoading ?
                    <LoadingSpinner />
                    :
                    birthday?.map((birthday, index) => (
                        <div className="upcomingbirthday d-flex jusctify-between align-items-center border-top border-bottom p-2" key={index}>
                            <div className="upcomingbirthdayimgname d-flex">
                                <div className="upcomingbirthdayimg">
                                    <img src={BASE_URL + birthday?.emp_foto} className="rounded-circle" alt={avatar} />
                                </div>
                                <div className="ms-2 upcomingbirthdayname">
                                    <h6 className="mb-1 font-14">{birthday.emp_name}</h6>
                                    <p className="mb-0 font-5 text-secondary">{birthday.emp_job_role}</p>
                                </div>
                            </div>
                            <div className="upcomingbirthdaydate">
                                <span>{moment(birthday?.emp_dob).format('Do MMM YYYY')}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default UpcomingBirthday