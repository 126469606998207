import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { AiOutlineAntDesign } from 'react-icons/ai';
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { FaAward, FaUserAlt } from 'react-icons/fa';
import { BsCalendarDate } from 'react-icons/bs';

const initialState = {
    award_name: '',
    emp_id: '',
    award_date: '',
}

const EditAward = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [employee, setEmployee] = useState();

    useEffect(() => {
        getAwardData(slug)
        getEmployee();
    }, [slug]);

    const getAwardData = (slug) => {
        editDataFromList(`hr-get-single-award-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.award])
            }
        })
    }
    const updateAwardData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-award-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/award');
                    toast.success("Award Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const getEmployee = () => {
        fetchAllDataAPI('hr-get-active-employee').then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="awardPage">
            <Helmet>
                <title>SATKARTAR::HR::EDIT Award</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">EDIT Designation</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateAwardData}>
                                <div className="col-12">
                                    <label for="award_name" className="form-label">Award Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaAward />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="award_name" name="award_name" placeholder="Award Name" onChange={handleInputs} required={true} value={values.award_name} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="emp_id" className="form-label">Select Employee</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserAlt />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_id" name="emp_id" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_id}>{values?.emp_name}</option>
                                            {employee?.map((employee) => (
                                                <option value={employee?.employee_id}>{employee?.emp_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label for="award_date" className="form-label">Holiday Date</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <BsCalendarDate />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="award_date" name="award_date" placeholder="Holiday Date" onChange={handleInputs} required={true} value={values.award_date} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditAward