import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillEdit, AiFillEye, AiOutlinePlus } from 'react-icons/ai'
import { useState } from 'react'
import { useEffect } from 'react'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../utils/api'
import { toast } from 'react-toastify'
import LoadingSpinner from '../components/LoadingSpinner'
import Swal from 'sweetalert2'
import { VscTrash } from 'react-icons/vsc'

const Employee = () => {
    const [employee, setEmployee] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllEmployee();
    }, []);

    const getAllEmployee = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-all-employee').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const handleStatusActive = (empid) => {
        activeStatus('hr-active-employee-status', empid).then((res) => {
            if (res.data.status === 1) {
                getAllEmployee();
            }
        })
    }
    const handleStatusInactive = (empid) => {
        activeStatus('hr-inactive-employee-status', empid).then((res) => {
            if (res.data.status === 1) {
                getAllEmployee();
            }
        })
    }
    const handleDesignationRemoveHr = (empid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('hr-remove-employee', empid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getAllEmployee();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="employeepage">
            <Helmet>
                <title>SATKARTAR::HR::EMPLOYEES</title>
            </Helmet>

            <ContentWrapper>
                <div className="card employeepage">
                    <h4 className="mb-0 text-uppercase">
                        All Employees
                        <Link to="/add-employee" className="btn btn-primary btnemployee"><AiOutlinePlus /> Add Employee</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Emp Code</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <LoadingSpinner />
                                    :
                                    employee?.map((employee, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                {employee?.emp_name}

                                                {(() => {
                                                    if (employee?.emp_leave_status === "Inactive") {
                                                        return (
                                                            <div className="sticky_header__availabl">
                                                                <span className="sticky_header__available_circle"></span>
                                                                <p>Available for work</p>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className="sticky_header__navailabl">
                                                                <span className="sticky_header__navailable_circle"></span>
                                                                <p>Not available for work</p>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td><span className="badge bg-success">{employee?.emp_code}</span></td>
                                            <td>{employee?.emp_mobile}</td>
                                            <td>{employee?.emp_company_email}</td>
                                            <td>{employee?.emp_job_role}</td>
                                            <td>
                                                {(() => {
                                                    if (employee?.emp_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success btnstatus" onClick={() => handleStatusActive(employee?.employee_id)}>Active</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger btnstatus" onClick={() => handleStatusInactive(employee?.employee_id)}>Inactive</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-employee/${employee?.employee_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <Link to={`/employee-details/${employee?.emp_code}`} className="btnDisplay"><AiFillEye /></Link>
                                                <button className="btnDelete" onClick={() => handleDesignationRemoveHr(employee?.employee_id)}><VscTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Emp Code</th>
                                    <th>Mobile</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Employee