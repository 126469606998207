import React from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editDataFromList, updateDataAPI } from '../utils/api';
import { AiOutlineAntDesign } from 'react-icons/ai';
import { toast } from 'react-toastify';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

const initialState = {
    notification_name: '',
}

const EditNotification = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);

    useEffect(() => {
        getNotificationData(slug)
    }, [slug]);

    const getNotificationData = (slug) => {
        editDataFromList(`hr-get-single-notification-data/${slug}`).then((res)=>{
            if(res.data.status===1){
                setInputValues(...[res.data.notification])
            }
        })
    }
    const updateNotificationData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-notification-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/notification');
                    toast.success("notification Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="holidayPage">
            <Helmet>
                <title>SATKARTAR::HR::EDIT Notification</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">EDIT Notification</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateNotificationData}>
                                <div className="col-12">
                                    <label htmlFor="notification_name" className="form-label">Notification Name</label>
                                    <div className="input-group">
                                        <textarea cols="30" rows="10" className="form-control border-start-0" id="notification_name" name="notification_name" placeholder="Notification Name" onChange={handleInputs} required={true} value={values.notification_name}></textarea>
                                    </div>
                                </div>
                                
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditNotification