import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useState, useEffect } from 'react'
import { getSingleDataFromDataBase, updateDataAPI, updateProfilePictureAPi } from '../utils/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'


const initialState = {
    hr_name: '',
    hr_email: '',
}

const MyProfile = () => {
    const [hrdata, setHrdata] = useState([]);
    const hrmobile = localStorage.getItem("hrmobile");
    const [values, setInputValues] = useState(initialState);
    const navigate = useNavigate();
    const [file, setFile] = useState('');
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/hr/";

    useEffect(() => {
        if(hrmobile){
            getHrData(hrmobile);
        }
        else {
            navigate("/");
        }
    }, [hrmobile]);

    const getHrData = (hrmobile) => {
        getSingleDataFromDataBase(`hr-get-my-profile-data/${hrmobile}`).then((res) => {
            if (res.data.status === 1) {
                setHrdata(...[res.data.hrdata])
                setInputValues(...[res.data.hrdata])

            }
        })
    }
    const updateProfileData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-profile-data/${hrmobile}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/my-profile');
                    toast.success("Profile Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    useEffect(() => {
        if (file) {
            try {
                const data = new FormData();
                data.append("hr_foto", file);
                updateProfilePictureAPi(`hr-update-profile-picture-data/${hrmobile}`, data).then((res) => {
                    getHrData(hrmobile);
                    toast.success("profile pic updated sussfully"); 
                })
            }
            catch (error) {
                toast.error("Connection failed!!")
            }
        }
    }, [file]);
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="main-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <img src={BASE_URL + hrdata?.hr_foto} alt="Admin" className="rounded-circle p-1 bg-primary" width="110" />
                                        <div className="mt-3">
                                            <h4>{hrdata?.hr_name}</h4>
                                            <p className="text-secondary mb-1">HR</p>
                                            <input type="file" name="hr_foto" id="hr_foto" className="hrupdatefoto" onChange={(e) => setFile(e.target.files[0])} />
                                            <label htmlFor="hr_foto" className="labelUpdateBtn">Update Picture </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <form onSubmit={updateProfileData}>
                                    <div className="card-body">
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Full Name</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <input type="text" name="hr_name" id="hr_name" className="form-control" onChange={handleInputs} value={values?.hr_name} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Email</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <input type="text" name="hr_email" id="hr_email" className="form-control" onChange={handleInputs} value={values?.hr_email} />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Mobile</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <input type="tel" name="hr_mobile" id="hr_mobile" className="form-control" onChange={handleInputs} value={values?.hr_mobile} minLength={10} maxLength={10} readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-9 text-secondary">
                                                <input type="submit" className="btn btn-primary px-4" value="Save Changes" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile