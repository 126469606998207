import React, { useEffect, useState } from 'react'
import { AiFillNotification, AiOutlinePlus } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import moment from 'moment';

const Annoucement = () => {
    const [notification, setNotification] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getALLActiveNotification();
    }, []);
    const getALLActiveNotification = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-active-notification').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setNotification(...[res.data.notification]);
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100 annoucementsection topsection">
            <div className="titlesection d-flex border-bottom" style={{justifyContent:"space-between"}}>
                <div className="mb-1"><h5>Notification/Annoucement</h5></div>
                <div className="mb-1">
                    <Link to="/notification"><AiOutlinePlus />Add</Link>
                </div>
            </div>
            <div className="annoucementbody bodysection">
                <div className="annoucement table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Notification</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <LoadingSpinner />
                                :
                                notification?.map((notification, index) => (
                                    <tr key={index}>
                                        <th>{moment(notification?.create_date).format('Do MMM YYYY')}</th>
                                        <th>{notification?.notification_name}</th>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Annoucement