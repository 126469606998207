import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiOutlineAntDesign} from 'react-icons/ai'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { toast } from 'react-toastify'
import { addSingleDataAPI } from '../utils/api'
import { useNavigate } from 'react-router-dom'

const AddDesignation = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleDesignation = async (e) =>{
        e.preventDefault();
        if(values){
            try{
                addSingleDataAPI('hr-add-designation',values).then((res)=>{
                    if(res.data.status===1){
                        toast.success("Designation has been added successfully!");
                        navigate('/designation');
                    }
                    else if(res.data.status===2){
                        toast.warning("This Designation already exit!!");
                    }
                    else{
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else{
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="designationPage">
            <div className="designationPage">
                <Helmet>
                    <title>SATKARTAR::HR::ADD DESIGNATION</title>
                </Helmet>
                <ContentWrapper>
                    <div className="col-xl-6">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div>
                                        <AiOutlineAntDesign className="svgAddIcons" />
                                    </div>
                                    <h5 className="mb-0 text-danger">ADD Designation</h5>
                                </div>
                                <hr />
                                <form className="row g-3" method="post" onSubmit={handleDesignation}>
                                    <div className="col-12">
                                        <label for="designation_name" className="form-label">Role Name</label>
                                        <div className="input-group">
                                            <span className="input-group-text bg-transparent">
                                                <AiOutlineAntDesign />
                                            </span>
                                            <input type="text" className="form-control border-start-0" id="designation_name" name="designation_name" placeholder="Designation Name" onChange={handleInputs} required={true} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-danger px-5">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </div>
        </div>
    )
}

export default AddDesignation