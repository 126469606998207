import React from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editDataFromList, updateDataAPI } from '../utils/api';
import { AiOutlineAntDesign } from 'react-icons/ai';
import { toast } from 'react-toastify';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { BsCalendarDate } from 'react-icons/bs';

const initialState = {
    holiday_name: '',
    holiday_date: '',
}

const EditHoliday = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);

    useEffect(() => {
        getHolidayData(slug)
    }, [slug]);

    const getHolidayData = (slug) => {
        editDataFromList(`hr-get-single-holiday-data/${slug}`).then((res)=>{
            if(res.data.status===1){
                setInputValues(...[res.data.holiday])
            }
        })
    }
    const updateHolidayData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-holiday-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/holiday');
                    toast.success("Holiday Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="holidayPage">
            <Helmet>
                <title>SATKARTAR::HR::EDIT HOLIDAY</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">EDIT HOLIDAY</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateHolidayData}>
                                <div className="col-12">
                                    <label htmlFor="holiday_name" className="form-label">Holiday Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineAntDesign />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="holiday_name" name="holiday_name" placeholder="Holiday Name" value={values.holiday_name} onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="holiday_date" className="form-label">Holiday Date</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <BsCalendarDate />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="holiday_date" name="holiday_date" placeholder="Holiday Date" value={values.holiday_date} onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditHoliday