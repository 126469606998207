import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addSingleDataAPI, fetchAllDataAPI } from '../utils/api';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { BsCalendarDate } from 'react-icons/bs';
import { FaAward, FaUserAlt } from 'react-icons/fa';
import { useEffect } from 'react';

const AddAward = () => {
    const navigate = useNavigate();
    const hrid = localStorage.getItem("hrdata");
    const [values, setUserValue] = useState();
    const [employee, setEmployee] = useState();

    useEffect(()=>{
        getEmployee();
    },[]);

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const getEmployee = () => {
        fetchAllDataAPI('hr-get-active-employee').then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const handleAward = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                const data = {hr_id:hrid, award_name:values.award_name, emp_id:values.emp_id, award_date:values.award_date};
                addSingleDataAPI('hr-add-award', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Award has been added successfully!");
                        navigate('/award');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Award already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="awardPage">
            <Helmet>
                <title>SATKARTAR::HR::ADD AWARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaAward className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Award</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleAward}>
                                <div className="col-12">
                                    <label for="award_name" className="form-label">Award Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaAward />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="award_name" name="award_name" placeholder="Award Name" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label htmlFor="emp_id" className="form-label">Select Employee</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserAlt />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_id" name="emp_id" required={true} onChange={handleInputs}>
                                            <option value="">Select Employee</option>
                                            {employee?.map((employee)=>(
                                                <option value={employee?.employee_id}>{employee?.emp_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label for="award_date" className="form-label">Holiday Date</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <BsCalendarDate />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="award_date" name="award_date" placeholder="Holiday Date" onChange={handleInputs} required={true} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddAward