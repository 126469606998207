import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FaUserAlt } from 'react-icons/fa'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiOutlineAntDesign } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../utils/api'
import { toast } from 'react-toastify'

const initialState = {
    leader_emp_id: '',
    emp_name:'',
}

const EditTeamLeader = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [employee, setEmployee] = useState();

    useEffect(() => {
        getEmployee();
        getTeamLeader(slug);
    }, [slug]);

    const getTeamLeader = (slug) => {
        editDataFromList(`hr-get-edit-team-leader-data/${slug}`).then((res) => {
            if (res.data.status === 1) {
                setInputValues(...[res.data.team])
            }
        })
    }
    const updateTeamData = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-team-leader-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/team-leader');
                    toast.success("Team Leader has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const getEmployee = () => {
        fetchAllDataAPI('hr-all-active-employee').then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="awardPage">
            <Helmet>
                <title>SATKARTAR::HR::EDIT TEAM LEADER</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">Edit Team Leader</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateTeamData}>
                                <div className="col-12">
                                    <label htmlFor="leader_emp_id" className="form-label">Select Employee</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserAlt />
                                        </span>
                                        <select className="form-control border-start-0" id="leader_emp_id" name="leader_emp_id" required={true} onChange={handleInputs}>
                                            <option value={values?.leader_emp_id}>{values?.emp_name}</option>
                                            {employee?.map((employee) => (
                                                <option value={employee?.employee_id}>{employee?.emp_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditTeamLeader