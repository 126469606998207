import React, { useEffect, useState } from 'react'
import { TbBeachOff } from "react-icons/tb";
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import moment from 'moment';


const HolidayEvent = () => {
    const [holiday, setHoliday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getAllHolidayThisYear()
    }, []);

    const getAllHolidayThisYear = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-all-holiday-this-year').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setHoliday(...[res.data.holiday])
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="holidayeventsection card-body card radius-10 overflow-hidden w-100 topsection">
            <div className="eventholidaytitle d-flex jusctify-between align-items-center">
                <div className="title">
                    <h5 className="mb-0">Holidays and Events</h5>
                </div>
                <div className="icons">
                    <TbBeachOff />
                </div>
            </div>
            <div className="p-2 ps ps--active-y bodysection">
                {isLoading ?
                    <LoadingSpinner />
                    :
                    holiday?.map((holiday, index) => (
                        <div className="eventholiday d-flex jusctify-between align-items-center border-top border-bottom p-2" key={index}>
                            <div className="ms-2 upcomingbirthdayname">
                                <h6 className="mb-1 font-14">{holiday?.holiday_name}</h6>
                            </div>
                            <div className="upcomingbirthdaydate">
                                <span>{moment(holiday?.holiday_date).format('Do MMM YYYY')}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default HolidayEvent