import React, { useEffect, useState } from 'react'
import { addSingleDataAPI, fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { useNavigate } from 'react-router-dom';
import { FaUserTie } from 'react-icons/fa';

const AddTeamLeader = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();
    const [employee, setEmployee] = useState([]);

    useEffect(() => {
        getEmployeeForTeamLeader();
    }, []);

    const getEmployeeForTeamLeader = () => {
        fetchAllDataAPI('hr-all-active-employee').then((res) => {
            if (res.data.status === 1) {
                setEmployee(...[res.data.employee]);
            }
            else {
                toast.warning("Check you connection");
            }
        })
    }

    const handleAddTeamLeader = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                addSingleDataAPI('hr-add-team-leader', values).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Team Leader has been added successfully!");
                        navigate('/team-leader');
                    }
                    else if (res.data.status === 2) {
                        toast.warning("This Team Leader already exit!!");
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }
    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::ADD TEAM LEADER</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Team Leader</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleAddTeamLeader}>
                                <div className="col-12">
                                    <label htmlFor="leader_emp_id" className="form-label">Select Employee</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <select className="form-control border-start-0" id="leader_emp_id" name="leader_emp_id" placeholder="Select Leave Policy" required={true} onChange={handleInputs}>
                                            <option value="">Select Employee Name</option>
                                            {employee?.map((employee) => (
                                                <option value={employee.employee_id}>{employee.emp_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddTeamLeader