import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import MobileOTP from "./pages/MobileOTP";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Employee from "./pages/Employee";
import Designation from "./pages/Designation";
import AddDesignation from "./pages/AddDesignation";
import EditDesignation from "./pages/EditDesignation";
import AddEmployee from "./pages/AddEmployee";
import EditEmployee from "./pages/EditEmployee";
import MyProfile from "./pages/MyProfile";
import EmployeeDetails from "./pages/EmployeeDetails";
import Holiday from "./pages/Holiday";
import AddHoliday from "./pages/AddHoliday";
import EditHoliday from "./pages/EditHoliday";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import EditNotification from "./pages/EditNotification";
import Award from "./pages/Award";
import AddAward from "./pages/AddAward";
import EditAward from "./pages/EditAward";
import Page404 from "./pages/Page404";
import TeamLeader from "./pages/TeamLeader";
import AddTeamLeader from "./pages/AddTeamLeader";
import EditTeamLeader from "./pages/EditTeamLeader";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mobile-otp" element={<MobileOTP />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/add-employee" element={<AddEmployee />} />
          <Route path="/edit-employee/:slug" element={<EditEmployee />} />
          <Route path="/employee-details/:slug" element={<EmployeeDetails />} />
          <Route path="/designation" element={<Designation />} />
          <Route path="/add-designation" element={<AddDesignation />} />
          <Route path="/edit-designation/:slug" element={<EditDesignation />} />
          <Route path="/holiday" element={<Holiday />} />
          <Route path="/add-holiday" element={<AddHoliday />} />
          <Route path="/edit-holiday/:slug" element={<EditHoliday />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/add-notification" element={<AddNotification />} />
          <Route path="/edit-notification/:slug" element={<EditNotification />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/award" element={<Award />} />
          <Route path="/add-award" element={<AddAward />} />
          <Route path="/edit-award/:slug" element={<EditAward />} />
          <Route path="/team-leader" element={<TeamLeader />} />
          <Route path="/add-team-leader" element={<AddTeamLeader />} />
          <Route path="/edit-team-leader/:slug" element={<EditTeamLeader />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
