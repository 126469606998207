import React from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiFillMobile, AiOutlineAntDesign, AiOutlineMail } from 'react-icons/ai'
import { FaAddressCard, FaCompactDisc, FaKeyboard, FaUserTie } from "react-icons/fa";
import { FcDepartment,FcLeave } from "react-icons/fc";
import { MdAirlineSeatReclineNormal, MdCurrencyRupee, MdOutlineDateRange, MdTitle } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { editDataFromList, fetchAllDataAPI, updateDataAPI } from '../utils/api';


const initialState = {
    emp_name: '',
    emp_code: '',
    emp_mobile: '',
    emp_personal_email: '',
    emp_company_email: '',
    emp_dob: '',
    emp_gender: '',
    emp_job_role: '',
    emp_designation: '',
    emp_basic_salary: '',
    emp_ctc: '',
    emp_department: '',
    emp_leave_policy: '',
    emp_joining_date: '',
    emp_seating_location: '',
    emp_employeer: '',
    emp_job_type: '',
    emp_work_form: '',
}

const EditEmployee = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [values, setInputValues] = useState(initialState);
    const [designation, setDesignation] = useState([]);
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getEmployeeData(slug)
        getDesignation();
        getCompany();
    }, [slug]);

    const getDesignation = () => {
        fetchAllDataAPI('hr-active-designation').then((res) => {
            if (res.data.status === 1) {
                setDesignation(...[res.data.designation]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }

    const getCompany = () => {
        fetchAllDataAPI('hr-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const getEmployeeData = (slug) => {
        editDataFromList(`hr-get-single-employee-data/${slug}`).then((res)=>{
            if(res.data.status===1){
                setInputValues(...[res.data.employee])
            }
        })
    }
    const updateEmployee = async (e) => {
        e.preventDefault();
        try {
            updateDataAPI(`hr-update-employee-data/${slug}`, values).then((res) => {
                if (res.data.status === 1) {
                    navigate('/employee');
                    toast.success("Employee Data has been updated successfully!!");
                }
                else {
                    toast.error("Something Wrongs!!");
                }
            })
        }
        catch (error) {
            toast.error("Connection failed!!")
        }
    }
    const handleInputs = e => {
        setInputValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    }
    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::EDIT Employee</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">EDIT Employee</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={updateEmployee}>
                                <div className="col-6">
                                    <label htmlFor="emp_name" className="form-label">Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_name" name="emp_name" placeholder="Employee Name" required={true} onChange={handleInputs} value={values?.emp_name} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_code" className="form-label">Employee Code</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_code" name="emp_code" placeholder="Employee Code" required={true} onChange={handleInputs} value={values?.emp_code} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_mobile" className="form-label">Mobile Number</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_mobile" name="emp_mobile" placeholder="Mobile Number" required={true} maxLength={10} minLength={10} onChange={handleInputs} value={values?.emp_mobile} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_personal_email" className="form-label">Personal Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="emp_personal_email" name="emp_personal_email" placeholder="Personal Email" required={true} onChange={handleInputs} value={values?.emp_personal_email} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_company_email" className="form-label">Company Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="emp_company_email" name="emp_company_email" placeholder="Company Email" required={true} onChange={handleInputs} value={values?.emp_company_email} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_dob" className="form-label">Date Of Birth</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="emp_dob" name="emp_dob" placeholder="DOB" required={true} onChange={handleInputs} value={values?.emp_dob} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_gender" className="form-label">Gender</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_gender" name="emp_gender" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_gender}>{values?.emp_gender}</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_job_role" className="form-label">Role</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdTitle />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_job_role" name="emp_job_role" placeholder="Select Role" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_job_role}>{values?.emp_job_role}</option>
                                            <option value="Center Head">Center Head</option>
                                            <option value="Team Leader">Team Leader</option>
                                            <option value="MIS">MIS</option>
                                            <option value="Developer">Developer</option>
                                            <option value="Web Designer">Web Designer</option>
                                            <option value="PPC">PPC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_designation" className="form-label">Designation</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineAntDesign />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_designation" name="emp_designation" placeholder="Designation" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_designation}>{values?.designation_name}</option>
                                            {designation?.map((designation) => (
                                                <option value={designation?.designation_id}>{designation?.designation_name}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_basic_salary" className="form-label">Basic Salary</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdCurrencyRupee />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_basic_salary" name="emp_basic_salary" placeholder="0.00" required={true} onChange={handleInputs} value={values?.emp_basic_salary} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_ctc" className="form-label">Total CTC</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdCurrencyRupee />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_ctc" name="emp_ctc" placeholder="0.00" required={true} onChange={handleInputs} value={values?.emp_ctc} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_department" className="form-label">Department</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FcDepartment />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_department" name="emp_department" placeholder="Department" required={true} onChange={handleInputs} value={values?.emp_department} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_leave_policy" className="form-label">Select Leave Policy</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FcLeave />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_leave_policy" name="emp_leave_policy" placeholder="Select Leave Policy" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_leave_policy}>{values?.emp_leave_policy}</option>
                                            <option value="Policy 1">Policy 1</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_joining_date" className="form-label">Date Of Joining</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdOutlineDateRange />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="emp_joining_date" name="emp_joining_date" placeholder="Date Of Joining" required={true} onChange={handleInputs} value={values?.emp_joining_date} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_seating_location" className="form-label">Seating Location</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdAirlineSeatReclineNormal />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_seating_location" name="emp_seating_location" placeholder="Seating Location" required={true} onChange={handleInputs} value={values?.emp_seating_location} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_employeer" className="form-label">Select Employeer</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaCompactDisc />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_employeer" name="emp_employeer" placeholder="Select Employeer" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_employeer}>{values?.company_name}</option>
                                            {company?.map((company) => (
                                                <option value={company?.company_id}>{company?.company_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_job_type" className="form-label">Job Type</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaAddressCard />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_job_type" name="emp_job_type" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_job_type}>{values?.emp_job_type}</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Permanent">Permanent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_work_form" className="form-label">Work From</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaKeyboard />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_work_form" name="emp_work_form" required={true} onChange={handleInputs}>
                                            <option value={values?.emp_work_form}>{values?.emp_work_form}</option>
                                            <option value="Work From Office">Work From Office</option>
                                            <option value="Work From Home">Work From Home</option>
                                            <option value="working from client location">working from client location</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default EditEmployee