import React from "react";
import loadingif from "../assets/loading-gif.gif";

export default function LoadingSpinner() {
    return (
        <tr>
            <td colSpan={15}>
                <div className="spinner-container">
                    <img src={loadingif} alt={loadingif} />
                </div>
            </td>
        </tr>
    );
}