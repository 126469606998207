import axios from "axios";

//const BASE_URL = "http://localhost/satkartarcrmapi/";
const BASE_URL = "https://satkartar.one/";

export const loginAPI = async (url, params) => {
    try {
        const sendmobileotp = await axios({
            method: "post",
            url: BASE_URL + url,
            data: { mobile: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return sendmobileotp;
    }
    catch (err) {
        return err;
    }
}

export const verifyMobileOTPAPI = async (url, params) => {
    try {
        const matchedmobileotp = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return matchedmobileotp;
    }
    catch (err) {
        return err;
    }
}

export const reSendMobileOTP = async (url, params) => {
    try {
        const resendOTP = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { mobile: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return resendOTP;
    }
    catch (err) {
        return err;
    }
}

export const addSingleDataAPI = async (url, params) => {
    try {
        const addHr = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return addHr;
    }
    catch (err) {
        return err;
    }
}
export const fetchAllDataAPI = async (url, params) => {
    try {
        const order = await axios({
            method: "get",
            url: BASE_URL + url,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        console.log(err);
        return err;
    }
}

export const activeStatus = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}
export const deactiveStatus = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}

export const removeDataFromList = async (url, params) => {
    try {
        const activeStatus = await axios({
            method: "POST",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return activeStatus;
    }
    catch (err) {
        return err;
    }
}

export const editDataFromList = async (url, params) => {
    try {
        const editData = await axios({
            method: "GET",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return editData;
    }
    catch (err) {
        return err;
    }
}
export const updateDataAPI = async (url, params) => {
    try {
        const updateData = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return updateData;
    }
    catch (err) {
        return err;
    }
}

export const getSingleDataFromDataBase = async (url, params) => {
    try {
        const singleData = await axios({
            method: "GET",
            url: BASE_URL + url,
            data: { id: params },
            headers: { "Content-Type": "multipart/form-data" },
        })
        return singleData;
    }
    catch (err) {
        return err;
    }
}
export const updateProfilePictureAPi = async (url, params) => {
    try {
        const updatePicture = await axios({
            method: "post",
            url: BASE_URL + url,
            data: params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return updatePicture;
    }
    catch (err) {
        return err;
    }
}
export const fetchAllDatasAPI = async (url, params) => {
    try {
        const order = await axios({
            method: "GET",
            url: BASE_URL + url,
            data:params,
            headers: { "Content-Type": "multipart/form-data" },
        })
        return order;
    }
    catch (err) {
        console.log(err);
        return err;
    }
}