import React, { useEffect, useState } from 'react'
import avatar from "../assets/avatar.webp";
import { GiTeapotLeaves } from 'react-icons/gi';
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { FaMobileAlt } from 'react-icons/fa';

const TodayLeave = () => {
    const [absence, setAbsence] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";

    useEffect(() => {
        getAbsence();
    }, [])

    const getAbsence = () => {
        setIsLoading(true)
        fetchAllDataAPI('hr-today-leave-absence').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setAbsence(...[res.data.tempabsence])
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100 leavetodaysection topsection">
            <div className="d-flex align-items-center upcomingtitle">
                <div>
                    <h5 className="mb-0">Today's Absence</h5>
                </div>
                <div className="birthdayicons">
                    <GiTeapotLeaves />
                </div>
            </div>
            <div className="p-2 ps ps--active-y bodysection">
                {isLoading ?
                    <LoadingSpinner />
                    :
                    absence?.map((absence, index) => (
                        <div className="upcomingbirthday d-flex jusctify-between align-items-center border-top border-bottom p-2">
                            <div className="upcomingbirthdayimgname d-flex">
                                <div className="upcomingbirthdayimg">
                                    <img src={BASE_URL + absence?.emp_foto} className="rounded-circle" alt={absence?.emp_foto} />
                                </div>
                                <div className="ms-2 upcomingbirthdayname">
                                    <h6 className="mb-1 font-14">{absence?.emp_name}</h6>
                                    <p className="mb-0 font-5 text-secondary">{absence?.emp_job_role}</p>
                                </div>
                            </div>
                            <div className="upcomingbirthdaydate">
                                <span><FaMobileAlt  /> {absence?.emp_mobile}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TodayLeave