import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Helmet } from 'react-helmet'
import { AiFillMobile, AiOutlineAntDesign, AiOutlineMail } from 'react-icons/ai'
import { FaAddressCard, FaCompactDisc, FaKeyboard, FaUserTie } from "react-icons/fa";
import { FcDepartment,FcLeave } from "react-icons/fc";
import { MdAirlineSeatReclineNormal, MdCurrencyRupee, MdOutlineDateRange, MdTitle } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { addSingleDataAPI, fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';

const AddEmployee = () => {
    const navigate = useNavigate();
    const [values, setUserValue] = useState();
    const [designation, setDesignation] = useState([]);
    const [company, setCompany] = useState([]);

    useEffect(()=>{
        getDesignation();
        getCompany();
    },[]);

    const getDesignation = () => {
        fetchAllDataAPI('hr-active-designation').then((res) => {
            if (res.data.status === 1) {
                setDesignation(...[res.data.designation]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }

    const getCompany = () => {
        fetchAllDataAPI('hr-active-company').then((res) => {
            if (res.data.status === 1) {
                setCompany(...[res.data.company]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleAddEmployee = async (e) =>{
        e.preventDefault();
        if(values){
            try{
                addSingleDataAPI('hr-add-employee',values).then((res)=>{
                    if(res.data.status===1){
                        toast.success("Employee has been added successfully!");
                        navigate('/employee');
                    }
                    else if(res.data.status===2){
                        toast.warning("This Employee already exit!!");
                    }
                    else{
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else{
            toast.error("Server internal error!!");
        }
    }

    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::ADD Employee</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-12">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <FaUserTie className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Employee</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleAddEmployee}>
                                <div className="col-6">
                                    <label htmlFor="emp_name" className="form-label">Name</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_name" name="emp_name" placeholder="Employee Name" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_code" className="form-label">Employee Code</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaUserTie />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_code" name="emp_code" placeholder="Employee Code" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_mobile" className="form-label">Mobile Number</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiFillMobile />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_mobile" name="emp_mobile" placeholder="Mobile Number" required={true} maxLength={10} minLength={10} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_personal_email" className="form-label">Personal Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="emp_personal_email" name="emp_personal_email" placeholder="Personal Email" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_company_email" className="form-label">Company Email</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="email" className="form-control border-start-0" id="emp_company_email" name="emp_company_email" placeholder="Company Email" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_dob" className="form-label">Date Of Birth</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="emp_dob" name="emp_dob" placeholder="DOB" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_gender" className="form-label">Gender</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineMail />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_gender" name="emp_gender" required={true} onChange={handleInputs}>
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_job_role" className="form-label">Role</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdTitle />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_job_role" name="emp_job_role" placeholder="Designation" required={true} onChange={handleInputs}>
                                            <option value="">Select Role</option>
                                            <option value="Center Head">Center Head</option>
                                            <option value="Team Leader">Team Leader</option>
                                            <option value="MIS">MIS</option>
                                            <option value="Developer">Developer</option>
                                            <option value="Web Designer">Web Designer</option>
                                            <option value="PPC">PPC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_designation" className="form-label">Designation</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <AiOutlineAntDesign />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_designation" name="emp_designation" placeholder="Designation" required={true} onChange={handleInputs}>
                                            <option value="">Select Designation</option>
                                            {designation?.map((designation)=>(
                                            <option value={designation?.designation_id}>{designation?.designation_name}</option>
                                            ))}
                                            
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_basic_salary" className="form-label">Basic Salary</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdCurrencyRupee />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_basic_salary" name="emp_basic_salary" placeholder="0.00" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_ctc" className="form-label">Total CTC</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdCurrencyRupee />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_ctc" name="emp_ctc" placeholder="0.00" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_department" className="form-label">Department</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FcDepartment />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_department" name="emp_department" placeholder="Department" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_leave_policy" className="form-label">Select Leave Policy</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FcLeave />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_leave_policy" name="emp_leave_policy" placeholder="Select Leave Policy" required={true} onChange={handleInputs}>
                                            <option value="">Select Leave Policy</option>
                                            <option value="1">Policy 1</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_joining_date" className="form-label">Date Of Joining</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdOutlineDateRange />
                                        </span>
                                        <input type="date" className="form-control border-start-0" id="emp_joining_date" name="emp_joining_date" placeholder="Date Of Joining" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_seating_location" className="form-label">Seating Location</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <MdAirlineSeatReclineNormal />
                                        </span>
                                        <input type="text" className="form-control border-start-0" id="emp_seating_location" name="emp_seating_location" placeholder="Seating Location" required={true} onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_employeer" className="form-label">Select Employeer</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaCompactDisc />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_employeer" name="emp_employeer" placeholder="Select Employeer" required={true} onChange={handleInputs}>
                                            <option value="">Select Employeer</option>
                                            {company?.map((company)=>(
                                            <option value={company?.company_id}>{company?.company_name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_job_type" className="form-label">Job Type</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaAddressCard />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_job_type" name="emp_job_type" required={true} onChange={handleInputs}>
                                            <option value="">Select Job Type</option>
                                            <option value="Full Time">Full Time</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Permanent">Permanent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <label htmlFor="emp_work_form" className="form-label">Work From</label>
                                    <div className="input-group">
                                        <span className="input-group-text bg-transparent">
                                            <FaKeyboard />
                                        </span>
                                        <select className="form-control border-start-0" id="emp_work_form" name="emp_work_form" required={true} onChange={handleInputs}>
                                            <option value="">Select Work From</option>
                                            <option value="Work From Office">Work From Office</option>
                                            <option value="Work From Home">Work From Home</option>
                                            <option value="working from client location">working from client location</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddEmployee