import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link } from 'react-router-dom'
import { AiFillEdit, AiOutlinePlus } from 'react-icons/ai'
import { activeStatus, fetchAllDataAPI, removeDataFromList } from '../utils/api'
import { toast } from 'react-toastify'
import LoadingSpinner from '../components/LoadingSpinner'
import Swal from 'sweetalert2'
import { VscTrash } from "react-icons/vsc";
import moment from 'moment'

const Notification = () => {
    const [notification, setNotification] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 10;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = notification.slice(firstIndex, lastIndex);
    const npage = Math.ceil(notification.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)

    useEffect(() => {
        getNotification();
    }, []);

    const getNotification = () => {
        setIsLoading(true);
        fetchAllDataAPI(`hr-all-notification`).then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false);
                setNotification(...[res.data.notification]);
            }
            else {
                toast.error("Check you connection!!");
            }
        })
    }
    const handleStatusActive = (notificationid) => {
        activeStatus('hr-active-notification-status', notificationid).then((res) => {
            if (res.data.status === 1) {
                getNotification();
            }
        })
    }
    const handleStatusInactive = (notificationid) => {
        activeStatus('hr-inactive-notification-status', notificationid).then((res) => {
            if (res.data.status === 1) {
                getNotification();
            }
        })
    }
    const handleRemoveNotification = (notificationid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'danger',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                removeDataFromList('hr-remove-notification', notificationid).then((res) => {
                    if (res.data.status === 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        getNotification();
                    }
                })
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                )
            }
        })
    }
    return (
        <div className="holidayPage">
            <Helmet>
                <title>SATKARTAR::HR::NOTIFICATION</title>
            </Helmet>
            <ContentWrapper>
                <div className="card employeepage">
                    <h4 className="mb-0 text-uppercase">
                        All Notification
                        <Link to="/add-notification" className="btn btn-primary btnemployee"><AiOutlinePlus /> Add Notification</Link>
                    </h4>
                    <div className="table pagetable table-responsive">
                        <table id="example" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Create Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <LoadingSpinner />
                                    :
                                    records?.map((notification, index) => (
                                        <tr key={notification?.notification_id}>
                                            <td>{index + 1}</td>
                                            <td>{notification?.notification_name}</td>
                                            <td>{moment(notification?.create_date).format('MMMM Do YYYY')}</td>
                                            <td>
                                                {(() => {
                                                    if (notification?.notification_status === "Active") {
                                                        return (
                                                            <span className="badge bg-success btnstatus" onClick={() => handleStatusActive(notification?.notification_id)}>Active</span>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <span className="badge bg-danger btnstatus" onClick={() => handleStatusInactive(notification?.notification_id)}>Inactive</span>
                                                        )
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                <Link to={`/edit-notification/${notification?.notification_id}`} className="btnEdit"><AiFillEdit /></Link>
                                                <button className="btnDelete" onClick={() => handleRemoveNotification(notification?.notification_id)}><VscTrash /></button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Create Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                        <nav>
                            <ul className="pagination justify-content-end round-pagination">
                                <li className="page-item">
                                    <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                </li>
                                {
                                    numbers.map((n, i) => (
                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                            <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                        </li>
                                    ))
                                }
                                <li className="page-item">
                                    <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default Notification