import React, { useEffect, useState } from 'react'
import avatar from "../assets/avatar.webp";
import { FaBirthdayCake } from 'react-icons/fa';
import Confetti from 'react-confetti'
import { fetchAllDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';

const TodayBirthday = () => {
    const [tbirthday, setTbirthday] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";

    useEffect(() => {
        getTodayBirthday()
    }, []);

    const getTodayBirthday = () => {
        setIsLoading(true);
        fetchAllDataAPI('hr-all-today-birthday').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setTbirthday(...[res.data.tbirthday]);
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100 topsection birthdaysectioncard">
            <Confetti></Confetti>
            <div className="d-flex align-items-center">
                <div>
                    <h5 className="mb-0">Today's Birthday</h5>
                </div>
            </div>
            <div className="p-2 birthdaysection bodysection">
                {isLoading ?
                    <LoadingSpinner />
                    :
                    tbirthday?.map((tbirthday, index) => (
                        <div className="todaybirthday border-top" key={index}>
                            <div className="todaybirthdayimgname mt-2">
                                <div className="todaybirthdayimg align-items-center">
                                    <img src={BASE_URL + tbirthday.emp_foto} className="rounded-circle" alt={tbirthday.emp_foto} />
                                </div>
                                <div className="ms-2 todaybirthdayname">
                                    <h3 className="mb-1 font-14"><FaBirthdayCake /> {tbirthday.emp_name}</h3>
                                    <p className="mb-0 font-5 text-secondary">{tbirthday.emp_job_role}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default TodayBirthday