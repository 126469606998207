import React, { useEffect, useState } from 'react'
import { GiTeapotLeaves } from "react-icons/gi";
import { fetchAllDataAPI } from '../utils/api';
import LoadingSpinner from './LoadingSpinner';
import moment from 'moment';

const LeaveReport = () => {
    const [leave, setLeave] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const BASE_URL = "http://localhost/satkartarcrmapi/assets/employee/";

    useEffect(() => {
        getLeaveReport()
    }, [])

    const getLeaveReport = () => {
        setIsLoading(true)
        fetchAllDataAPI('hr-get-leave-report').then((res) => {
            if (res.data.status === 1) {
                setIsLoading(false)
                setLeave(...[res.data.leave])
            }
            else {
                setIsLoading(false)
            }
        })
    }
    return (
        <div className="card radius-10 overflow-hidden w-100 topsection">
            <div className="leavetitle d-flex align-items-center border-bottom">
                <div className="title mb-1">
                    <h5 className="">Leave Report</h5>
                </div>
                <div className="icons mb-1">
                    <GiTeapotLeaves />
                </div>
            </div>
            <div className="p-2 bodysection">
                <div className="leavebody table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Leave Type</th>
                                <th>From Date</th>
                                <th>End Date</th>
                                <th>Request Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <LoadingSpinner />
                                :
                                leave?.map((leave, index) => (
                                    <tr>
                                        <th>{index + 1}</th>
                                        <th><img className="rounded-circle" src={BASE_URL + leave?.emp_foto} alt={leave?.emp_foto} width={50} /></th>
                                        <th>{leave?.emp_name}</th>
                                        <th>{leave?.leave_type}</th>
                                        <th>{moment(leave?.leave_start_date).format('Do MMM YYYY')}</th>
                                        <th>{moment(leave?.leave_end_date).format('Do MMM YYYY')}</th>
                                        <th>{moment(leave?.request_date).format('Do MMM YYYY')}</th>
                                        <th>
                                            {(() => {
                                                if (leave?.leave_status === "Approved") {
                                                    return (
                                                        <button className="badge bg-success text-dark border-0 text-white">{leave?.leave_status}</button>
                                                    )
                                                }
                                                else if (leave?.leave_status === "Pending") {
                                                    return (
                                                        <button className="badge bg-warning text-dark border-0 text-white">{leave?.leave_status}</button>
                                                    )
                                                }
                                                else if (leave?.leave_status === "Reject") {
                                                    return (
                                                        <button className="badge bg-danger text-dark border-0 text-white">{leave?.leave_status}</button>
                                                    )
                                                }
                                            })()}

                                        </th>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Leave Type</th>
                                <th>From Date</th>
                                <th>End Date</th>
                                <th>Request Date</th>
                                <th>Status</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LeaveReport