import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addSingleDataAPI } from '../utils/api';
import { toast } from 'react-toastify';
import ContentWrapper from '../components/contentwrapper/ContentWrapper';
import { Helmet } from 'react-helmet';
import { AiOutlineAntDesign } from 'react-icons/ai';

const AddNotification = () => {
    const navigate = useNavigate();
    const hrid = localStorage.getItem("hrdata");
    const [values, setUserValue] = useState();

    const handleInputs = e => {
        setUserValue({
            ...values,
            [e.target.name]: e.target.value,
        });
    };
    const handleNotification = async (e) => {
        e.preventDefault();
        if (values) {
            try {
                const data = {hr_id:hrid, notification_name:values.notification_name};
                addSingleDataAPI('hr-add-notification', data).then((res) => {
                    if (res.data.status === 1) {
                        toast.success("Notification has been added successfully!");
                        navigate('/notification');
                    }
                    else {
                        toast.error("Something Wrongs!!");
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        else {
            toast.error("Server internal error!!");
        }
    }
    return (
        <div className="designationPage">
            <Helmet>
                <title>SATKARTAR::HR::ADD NOTIFICATION</title>
            </Helmet>
            <ContentWrapper>
                <div className="col-xl-6">
                    <div className="card border-top border-0 border-4 border-danger">
                        <div className="card-body">
                            <div className="card-title d-flex align-items-center">
                                <div>
                                    <AiOutlineAntDesign className="svgAddIcons" />
                                </div>
                                <h5 className="mb-0 text-danger">ADD Notification</h5>
                            </div>
                            <hr />
                            <form className="row g-3" method="post" onSubmit={handleNotification}>
                                <div className="col-12">
                                    <label for="notification_name" className="form-label">Notification Name</label>
                                    <div className="input-group">
                                        <textarea cols="30" rows="10" className="form-control border-start-0" id="notification_name" name="notification_name" placeholder="Notification Name" onChange={handleInputs} required={true}></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger px-5">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default AddNotification