import React, { useEffect, useState } from 'react'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { AiOutlineUser } from 'react-icons/ai'
import { Helmet } from 'react-helmet'
import TodayBirthday from '../components/TodayBirthday'
import UpcomingBirthday from '../components/UpcomingBirthday'
import HolidayEvent from '../components/HolidayEvent'
import TodayLeave from '../components/TodayLeave'
import Annoucement from '../components/Annoucement'
import LeaveReport from '../components/LeaveReport'
import { fetchAllDataAPI } from '../utils/api'
import { toast } from 'react-toastify'

const Dashboard = () => {
    const[empdata, setEmpdata] = useState([]);

    useEffect(()=>{
        getDashboardData()
    },[]);

    const getDashboardData = () => {
        fetchAllDataAPI('hr-dashboard-about-emp-data').then((res)=>{
            if(res.data.status===1){
                setEmpdata(...[res.data.empdata])
            }
            else{
                toast.warning("Check you connection");
            }
        })
    }

    return (
        <div className="hrDashboard">
            <Helmet>
                <title>SATKARTAR:HR::DASHBOARD</title>
            </Helmet>
            <ContentWrapper>
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col">
                        <div className="card radius-10 topsection">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-primary">{empdata?.temp}</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Total Employee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card radius-10 topsection">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-primary">{empdata?.taemp}</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Total Active Employee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card radius-10 topsection">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-danger">{empdata?.tdemp}</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-danger" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Total Deactive Employee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card radius-10 topsection">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 text-secondary">{empdata?.tlemp}</h5>
                                    <div className="ms-auto">
                                        <AiOutlineUser />
                                    </div>
                                </div>
                                <div className="progress my-2">
                                    <div className="progress-bar bg-secondary" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: "65%" }}></div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <p className="mb-0">Total On Leave Employee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4 d-flex birthdaysection">
                        <TodayBirthday />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4 d-flex birthdaysection">
                        <UpcomingBirthday />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4 d-flex birthdaysection">
                        <HolidayEvent />
                    </div>
                </div>
                <div className="row leavesections">
                    <div className="col-12 col-lg-6 col-xl-8 d-flex leavesection">
                        < LeaveReport />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4">
                        <TodayLeave />
                        <Annoucement />
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default Dashboard
